import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import SignUpComponent from "../components/sign-up"

const JoinPocamPage = ({ location }) => {
  return (
    <Layout>
      <SEO
        location={location}
        image="/images/pocam-white-bg.png"
        title="Free for a limited time: The official POCAM membership"
        description="Register today for your official POCAM membership – with benefits to support, mentor, enrich and connect BIPOC Professionals. Sign up for free while you can."
      />
      <section className="bg-dark text-white py-4 py-lg-5">
        <Container>
          <Row>
            <Col md="8">
              <h1 className="display-4 mb-3">Join POCAM</h1>
              <p className="lead">
                People of Colour in Advertising and Marketing (POCAM) brings together individuals in the Canadian advertising and marketing industry who identify as BIPOC.
              </p>
              <p>
                POCAM exists to ensure that the voice, vision and talent of our incredible community of BIPOC professionals are fully and fairly present in our industry, and we want to make ourselves visible to each other, so that we can support, protect and celebrate each other.
              </p>
              <p>
                Please complete this Membership profile form if you would like to become a Member of POCAM and join our vibrant, growing community! Annual membership fees are waived for now. That means for the time being, you have access to all our events, sharing, connecting and networking opportunities for free! 
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-4 py-lg-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeEjEip150ISl4OVcHDoOY27RtIBRmhg5JRKKBnPMzL8dypSg/viewform?embedded=true" width="100%" height="8520" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
            </Col>
          </Row>
        </Container>
      </section>

      <SignUpComponent />
    </Layout>
  )
}

export default JoinPocamPage